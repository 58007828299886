import React from "react";

export default () => {
  return (
    <div className="flex flex-col w-full md:pt-10">
      <div className="flex flex-row flex-wrap justify-center items-center sm:flex-nowrap">
        <div className="flex flex-col md:p-5 md:w-5/6 lg:w-3/4">
          <div class="card">
            <div class="card-body">
              <div class="flex flex-row flex-wrap items-center justify-center md:justify-left md:flex-nowrap">
                <img
                  class="object-contain h-36 w-36 rounded-md m-4 md:h-48 md:w-48 lg:h-64 lg:w-64"
                  src="/img/dana2-edit.jpg"
                  alt="dana"
                />

                <div class="flex-col">
                  <a href="/">
                    <h1 class="card-title text-4xl mb-0 text-center md:text-left">dr. dana calacci</h1>
                    <p class="italic">
                    she/her they/them
                    </p>
                  <p class="pt-1 italic font-bold">
                    assistant professor, {" "}
                    <a
                      class="underline font-bold not-italic"
                      href="https://ist.psu.edu/"
                    >
                        Penn State IST 
                    </a>
                  </p>
                  </a>
                  <div class="flex-row justify-center text-center mt-2 mb-2 underline md:text-left md:justify-left">
                    <a
                      href="mailto:dcalacci@psu.edu"
                      class="text-gray-600 font-bold text-base hover:underline p-2 pl-0"
                    >
                      email
                    </a>
                    <a
                      href="https://twitter.com/dcalacci"
                      class="text-gray-600 font-bold text-base hover:underline p-2"
                    >
                      twitter/X
                    </a>
                    <a
                      rel="me"
                      href="https://heck.town/@dan"
                      class="text-gray-600 font-bold text-base hover:underline p-2"
                    >
                      mastodon
                    </a>
                    <a
                      href="https://scholar.google.com/citations?user=g13Vo5oAAAAJ"
                      class="text-gray-600 font-bold text-base hover:underline p-2"
                    >
                      g. scholar
                    </a>
                  </div>
                  <p>
                    I study how data and AI impact communities.
                  </p>
                  <p class="pt-1">
                    Right now, I'm focused on how algorithmic management is
                    changing the reality of work, how data stewardship and
                    participatory design can help create alternate working
                    futures, and on the data rights of platform workers.
                  </p>

                </div>
              </div>
            </div>
          </div>
          <div class="w-full items-center justify-center flex flex-row">
          <div class="bg-pink-100 pl-5 pr-5 pb-5 m-5 rounded-md w-full md:w-2/3">
            <p class="text-center italic font-bold pt-5 pb-2">🔥 recruiting students 🔥</p>
            <p>
              I'm looking for PhD students to join me in building my lab at Penn State. 
              Interested? Send me an email with 'building working futures' in the subject and tell me why you want to join. Please review my recent papers before cold e-mailing me. 
            </p>
            <br />
          </div>
          </div>

          <div class="divider">
            <div class="underline flex pl-10 pr-10 justify-start text-gray-900 text-xl">
              <a
                href="/"
                class="font-bold hover:underline hover:bg-black hover:text-pink-100 p-2"
              >
                home
              </a>
              <a
                href="/news"
                class="font-bold hover:underline hover:bg-black hover:text-pink-100 p-2"
              >
                news
              </a>
              <a
                href="/art"
                class="font-bold hover:underline hover:bg-black hover:text-pink-100 p-2"
              >
                art
              </a>

              <a
                href="/cv/calacci-cv-latest.pdf"
                class="font-bold hover:underline hover:bg-black hover:text-pink-100 p-2"
              >
                CV
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
